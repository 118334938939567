import React, { useState } from 'react';
import {
    Box,
    List,
    ListItemButton,
    ListItemText,
    Collapse,
    Typography,
    Paper,
    Chip,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
} from '@mui/material';
import { ExpandLess, ExpandMore, Folder, FolderOpen } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';

const StyledList = styled(List)({
    width: '100%',
    backgroundColor: '#fff',
});

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
        '& .MuiSvgIcon-root': {
            color: theme.palette.primary.contrastText,
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
}));

const StyledTreeLine = styled('div')(({ theme, level }) => ({
    position: 'absolute',
    top: 0,
    left: `${level * 20}px`,
    width: '1px',
    height: '100%',
    backgroundColor: theme.palette.divider,
}));

function HierarchyTreeView({
    hierarchyData,
    selectedHierarchyId,
    handleSelectHierarchy,
    fetchHierarchyData,
}) {
    const [expanded, setExpanded] = useState({});
    const [labelDialogOpen, setLabelDialogOpen] = useState(false);
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [currentNode, setCurrentNode] = useState(null);
    const [newLabel, setNewLabel] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    const toggleExpand = (id) => {
        setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleOpenLabelDialog = (node) => {
        setCurrentNode(node);
        // 既存のラベルがあればセット
        setNewLabel(node.label || '');
        // ラベルが存在すれば編集モード
        setIsEditing(!!node.label);
        setLabelDialogOpen(true);
    };

    const handleCloseLabelDialog = () => {
        setLabelDialogOpen(false);
        setCurrentNode(null);
        setNewLabel('');
    };

    const handleSaveLabel = async () => {
        if (!currentNode) return;

        try {
            const endpoint = isEditing
                ? '/backend/edit_label'
                : '/backend/add_label';

            const response = await axios.post(endpoint, {
                hierarchyId: currentNode.id,
                label: newLabel,
            });

            if (response.status === 200) {
                setSuccessDialogOpen(true);
                fetchHierarchyData();
            } else {
                console.error('バックエンドからエラー:', response.data.error);
                alert(`ラベルの保存に失敗しました: ${response.data.error || '不明なエラー'}`);
            }
        } catch (error) {
            console.error('通信エラー:', error);
            alert('ラベルの保存中に通信エラーが発生しました。');
        } finally {
            handleCloseLabelDialog();
        }
    };

    const handleSuccessDialogClose = () => {
        setSuccessDialogOpen(false);
        window.location.reload();
    };

    const renderHierarchy = (nodes, level = 0) => (
        <React.Fragment key={nodes.id}>
            <Box sx={{ position: 'relative' }}>
                {level > 0 && <StyledTreeLine level={level} />}
                <StyledListItemButton
                    selected={selectedHierarchyId === nodes.id}
                    onClick={() => handleSelectHierarchy(nodes)}
                    sx={{ pl: level * 2 + 2 }}
                >
                    {nodes.children && nodes.children.length > 0 ? (
                        expanded[nodes.id] ? (
                            <ExpandLess
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleExpand(nodes.id);
                                }}
                            />
                        ) : (
                            <ExpandMore
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleExpand(nodes.id);
                                }}
                            />
                        )
                    ) : (
                        <Box sx={{ width: 24 }} />
                    )}
                    {nodes.children && nodes.children.length > 0 ? (
                        expanded[nodes.id] ? (
                            <FolderOpen sx={{ ml: 1, mr: 1 }} />
                        ) : (
                            <Folder sx={{ ml: 1, mr: 1 }} />
                        )
                    ) : (
                        <Folder sx={{ color: 'action.disabled', ml: 1, mr: 1 }} />
                    )}
                    <ListItemText
                        primary={
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                    <Typography
                                        variant="body1"
                                        noWrap
                                        sx={{
                                            fontWeight: selectedHierarchyId === nodes.id ? 'bold' : 'normal',
                                        }}
                                    >
                                        {nodes.name}
                                    </Typography>
                                    {nodes.label && (
                                        <Chip
                                            label={nodes.label}
                                            size="small"
                                            color="secondary"
                                            sx={{ ml: 2 }}
                                        />
                                    )}
                                </Box>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenLabelDialog(nodes);
                                    }}
                                    sx={{ ml: 2 }}
                                >
                                    {nodes.label ? 'ラベルを変更' : 'ラベルを追加'}
                                </Button>
                            </Box>
                        }
                    />
                </StyledListItemButton>
            </Box>
            {nodes.children && nodes.children.length > 0 && (
                <Collapse in={expanded[nodes.id]} timeout="auto" unmountOnExit>
                    {nodes.children.map((node) => renderHierarchy(node, level + 1))}
                </Collapse>
            )}
        </React.Fragment>
    );

    return (
        <Paper elevation={2} sx={{ maxHeight: '70vh', overflow: 'auto' }}>
            <StyledList component="nav" disablePadding>
                {hierarchyData.map((data) => renderHierarchy(data))}
            </StyledList>

            {/* ラベル追加/編集ダイアログ */}
            <Dialog open={labelDialogOpen} onClose={handleCloseLabelDialog}>
                <DialogTitle>{isEditing ? 'ラベルを変更' : 'ラベルの追加'}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="ラベル"
                        type="text"
                        fullWidth
                        value={newLabel}
                        onChange={(e) => setNewLabel(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseLabelDialog}>キャンセル</Button>
                    <Button onClick={handleSaveLabel} color="primary">
                        保存
                    </Button>
                </DialogActions>
            </Dialog>

            {/* ラベル保存成功ダイアログ */}
            <Dialog open={successDialogOpen} onClose={handleSuccessDialogClose}>
                <DialogTitle>ラベルの保存</DialogTitle>
                <DialogContent>
                    <Typography>ラベルが正常に保存されました。</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSuccessDialogClose} color="primary">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
}

export default HierarchyTreeView;