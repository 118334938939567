import {
  ArrowBack as ArrowBackIcon,
  ChevronRight as ChevronRightIcon,
  Person as PersonIcon,
  Send as SendIcon,
  SkipNext as SkipNextIcon,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { marked } from 'marked';
import React, { useEffect, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import Consent from '../consent/Consent';

const StyledPopover = styled((props) => (
  <Popover
    {...props}
    classes={{ paper: props.className }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    position: 'relative',
    marginTop: '8px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'visible',
    maxWidth: '280px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-15px',
      left: '30px',
      width: 0,
      height: 0,
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '15px solid #fff',
    },
  },
}));

const StartChat = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [hasHistory, setHasHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    'token',
    'refresh_token',
    'consent',
  ]);
  const [userId, setUserId] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState('');
  const [userData, setUserData] = useState({
    id: '',
    password: '',
    icon: '',
    name: '',
    affiliation: '',
    skill: '',
    role: '',
  });
  const [showInitialScreen, setShowInitialScreen] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [showResolutionOptions, setShowResolutionOptions] = useState(false);
  const [showFurtherOptions, setShowFurtherOptions] = useState(false);
  const [isQuestionInput, setIsQuestionInput] = useState(false);
  const [questionContent, setQuestionContent] = useState('');
  const [characterPopoverOpen, setCharacterPopoverOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [modelPopoverOpen, setModelPopoverOpen] = useState(false);
  const [modelPopoverAnchorEl, setModelPopoverAnchorEl] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);
  const [hierarchyData, setHierarchyData] = useState([]);
  const [labelMissingDialogOpen, setLabelMissingDialogOpen] = useState(false);
  const currentNodes =
    currentPath.length === 0
      ? hierarchyData
      : currentPath[currentPath.length - 1].children;

  // 履歴管理
  const [histories, setHistories] = useState([]);
  const [currentHistoryId, setCurrentHistoryId] = useState(null);
      
  // ユーザーの同意状況を確認
  const checkConsentStatus = async () => {
    const consentCookie = cookies.consent;
    if (consentCookie) {
      const lastConsentDate = new Date(consentCookie);
      const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      if (lastConsentDate < thirtyDaysAgo) {
        setShowConsentModal(true);
      } else {
        setConsentGiven(true);
      }
    } else {
      setShowConsentModal(true);
    }
  };

  // 同意を保存
  const handleConsent = async () => {
    setCookie('consent', new Date().toISOString(), { path: '/' });
    setConsentGiven(true);
    setShowConsentModal(false);
  };

  // ユーザーデータを取得
  useEffect(() => {
    async function fetchData() {
      // 7文字のランダムな文字列を生成
      function generateRandomString(length) {
        const characters =
          'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * characters.length)
          );
        }
        return result;
      }

      // 暗号化されたものを復号化（CryptoJS使用）
      function aesDecryptCryptoJS(encryptedData, keyStr, ivStr) {
        const keyWordArray = CryptoJS.enc.Base64.parse(keyStr);
        const ivWordArray = CryptoJS.enc.Base64.parse(ivStr);

        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, keyWordArray, {
          iv: ivWordArray,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        });

        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedText;
      }

      // ユーザーデータを取得
      if (location.state && location.state.userId) {
        // location.stateが存在する場合は、その値を使用
        setUserId(location.state.userId);
        checkConsentStatus();
      } else {
        // URLからemployeeNumberのクエリパラメータを取得
        const queryString = new URLSearchParams(window.location.search);
        const keyBase64 = btoa('12345678901234567890123456789012');
        const encodedEncryptedEmployeeNumber = decodeURIComponent(
          queryString.get('employeeNumber')
        );
        const ivBase64 = decodeURIComponent(queryString.get('iv'));

        // 両方のパラメータが存在しない場合、エラーページに遷移
        if (!encodedEncryptedEmployeeNumber && !ivBase64) {
          console.log('クエリパラメータが存在しません');
          navigate('/authfailure');
          return;
        }

        // CryptoJSの復号化関数を使用してemployeeNumberFromParamを取得
        const employeeNumberFromParam = aesDecryptCryptoJS(
          encodedEncryptedEmployeeNumber,
          keyBase64,
          ivBase64
        );
        console.log('Decrypted text:', employeeNumberFromParam);
        try {
          // クエリパラメータが存在しない場合は、サーバーに問い合わせる
          const response = await axios.post(
            '/backend/getcookie',
            {
              employeeNumber: employeeNumberFromParam,
              withCredentials: true,
            }
          );
          console.log(response);
          setCookie('token', response.data.access_token);
          setCookie('refresh_token', response.data.refresh_token);

          if (response.data.status === 'exists') {
            setUserId(response.data.userId);
            checkConsentStatus();
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // 7文字のランダムな文字列を生成
            const randomPassword = generateRandomString(7);
            let userData = {
              id: employeeNumberFromParam,
              password: randomPassword,
              icon: 'null',
              name: '',
              affiliation: '',
              skill: '',
              skill_level: 5,
            };

            // ユーザーデータを登録
            const res = await axios.post(
              `/backend/adduserdata`,
              {
                userData: userData,
              },
              {
                withCredentials: true,
              }
            );
            console.log(res);

            // トップページにで必要な情報を取得
            const resTop = await axios.post(
              `/backend/totop`,
              {
                id: userData.id,
              },
              {
                withCredentials: true,
              }
            );
            console.log(resTop);
            setCookie('token', resTop.data.access_token);
            setCookie('refresh_token', resTop.data.refresh_token);

            // 通知情報を取得
            const resNotification = await axios.post(
              `/backend/getnotifications`,
              {
                user_id: userData.id,
                withCredentials: true,
              }
            );
            console.log(resNotification);
            const notificationsData = resNotification.data;
            const notificationCountData = notificationsData.length;

            // ユーザーページに遷移
            navigate('/user', {
              state: {
                userId: userData.id,
                notificationCount: notificationCountData,
                notifications: notificationsData,
              },
            });
            checkConsentStatus();
          } else {
            console.error('Error fetching data: ', error);
            navigate('/authfailure');
          }
        }
      }
    }
    fetchData();
  }, [location, navigate, setCookie]);

  // ユーザーデータを取得するための useEffect を追加
  useEffect(() => {
    console.log('ユーザーデータを取得します');
    async function fetchUserData() {
      console.log('userId:', userId);
      try {
        if (!userId) {
          return;
        }
        const res = await axios.post(
          `/backend/getuserdata`,
          {
            user_id: userId,
            withCredentials: true,
          }
        );
        console.log(res);
        setUserData(res.data.user);
      } catch (error) {
        console.error('ユーザーデータの取得中にエラーが発生しました:', error);
      }
    }

    // ユーザーの同意が得られた場合のみユーザーデータを取得
    if (consentGiven) {
      fetchUserData();
    }
  }, [consentGiven, userId]);

  // 通知を取得
  useEffect(() => {
    console.log('通知を取得します');
    async function fetchNotification() {
      try {
        if (!userId) {
          return;
        }
        const res = await axios.post(
          `/backend/getnotifications`,
          {
            user_id: userId,
            withCredentials: true,
          }
        );
        console.log(res);
        setNotifications(res.data);
        setNotificationCount(res.data.length);
      } catch (error) {
        console.error('通知の取得中にエラーが発生しました:', error);
      }
    }

    // ユーザーの同意が得られた場合のみ通知を取得
    if (consentGiven) {
      fetchNotification();
    }
  }, [consentGiven, userId]);

  // 階層データの取得
  const fetchHierarchyData = async () => {
    try {
      const response = await axios.get('/backend/gethierarchy');
      setHierarchyData(response.data);
    } catch (error) {
      console.error('階層データの取得に失敗しました', error);
    }
  };

  // モデルが選択されていない場合、キャラクターポップオーバーを表示
  useEffect(() => {
    if (!selectedModel) {
      setCharacterPopoverOpen(true);
    }
  }, [selectedModel]);

  // 階層データの取得
  useEffect(() => {
    fetchHierarchyData();
  }, []);

  // メッセージが更新されたらスクロールを最下部に移動
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // モデル選択ボタンをクリックしたときの処理
  const handleModelButtonClick = (event) => {
    setModelPopoverAnchorEl(event.currentTarget);
    setModelPopoverOpen(true);
    setCharacterPopoverOpen(false);
  };

  // モデルを選択したときの処理
  const handleModelSelect = (modelType, label, name) => {
    setSelectedModel({ type: modelType, label: label, name: name });
    setModelPopoverOpen(false);
    setCurrentPath([]);
    // トーク履歴がある場合は削除ボタンを有効にする
    if (messages.length > 0) {
      setHasHistory(true);
    }
    // メッセージの履歴をクリア
    setMessages([]);
    document.cookie =
      'messages=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  // 戻るボタンの処理
  const handleBack = () => {
    setCurrentPath(currentPath.slice(0, currentPath.length - 1));
  };

  // 階層をレンダリングする関数
  const renderHierarchy = (nodes) =>
    nodes.map((node) => (
      <ListItem key={node.id} disablePadding>
        <ListItemButton
          onClick={() => {
            if (!node.label) {
              setLabelMissingDialogOpen(true);
              return;
            }
            handleModelSelect('internal', node.label, node.name);
            setModelPopoverOpen(false);
            setCurrentPath([...currentPath, node]);
          }}
        >
          <ListItemText
            primary={`${node.name}の ChatGPT`}
            secondary={
              node.children && node.children.length > 0
                ? `${node.name}の質問に適しています。`
                : `${node.name}に関する質問に適しています。`
            }
            primaryTypographyProps={{ fontWeight: 'bold' }}
          />
          {node.children && node.children.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setCurrentPath([...currentPath, node]);
              }}
              edge="end"
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </ListItemButton>
      </ListItem>
    ));

  // ラベルをサニタイズする関数
  const sanitizeLabel = (label) => {
    // 空白をアンダースコアに置換
    let sanitized = label.replace(/\s+/g, '_');
    
    // 連続するアンダースコアを単一にする
    sanitized = sanitized.split('_').filter(part => part).join('_');
    
    // 先頭と末尾のアンダースコアを削除
    sanitized = sanitized.replace(/^_+|_+$/g, '');
    
    return sanitized;
  };

  const constructChromaName = () => {
    const sanitizedLabels = currentPath.map(node => sanitizeLabel(node.label));
    return sanitizedLabels.join('_');
  };
  
  // メッセージを送信したときの処理
  const handleSendMessage = async () => {
    // ローディング開始
    setIsLoading(true);
    setLastUserMessage(message);
    setErrorMessage('');
  
    // 質問IDを初期化
    let questionId = null;
  
    // メッセージが空の場合は何もしない
    if (message.trim() !== '') {
      // isQuestionInputがtrueの場合、質問内容を取得して質問ページに遷移
      if (isQuestionInput) {
        setQuestionContent(message);
        navigate('/question', {
          state: {
            userId,
            questionContent: message,
            notificationCount,
            notifications,
            userData,
          },
        });
        return;
      }
  
      // メッセージリストに追加
      const updatedMessages = [...messages, { isUser: true, text: message }];
      setMessages(updatedMessages);
      setHasHistory(true);
  
      try {
        // 質問IDを取得する（必要に応じてリクエスト）
        let saveRes = await axios.post(
          `/backend/save_chat_question`,
          { userId, text: message },
          { withCredentials: true }
        );
  
        const newHistoryId = saveRes.data.historyId;
        setCurrentHistoryId(newHistoryId);
  
        // 既存の履歴に追加（newHistoryIdを使用）
        saveRes = await axios.post(
          `/backend/save_chat_question`,
          { userId, text: message, historyId: newHistoryId },
          { withCredentials: true }
        );
  
        questionId = saveRes.data?.id || null;
  
        console.log('保存された質問ID:', questionId);
  
        // リクエストデータを準備
        const requestData = {
          userId,
          historyId: newHistoryId,
          questionId,
          settings: { model: 'gpt-4o-mini', historyCount: 0, temperature: 1.0 },
          text: message,
          messages: updatedMessages.slice(-15),
          withCredentials: true,
        };
  
        console.log('送信データ:', requestData);
  
        // サーバーにリクエストを送信
        let chatRes;
        if (selectedModel?.type === 'standard') {
          // 通常のChatGPT
          console.log('通常のChatGPTに送信します');
          chatRes = await axios.post(`/backend/send_chat_question`, requestData);
        } else if (selectedModel?.type === 'internal') {
  
          // Chromaの名前を生成
          let chroma_name = constructChromaName();
          requestData.chroma_name = chroma_name;
  
          // 社内文書用ChatGPT
          console.log('社内文書用ChatGPTに送信します');
          chatRes = await axios.post(
            `/backend/send_chat_question_internal_documents`,
            requestData
          );
        } else {
          // モデル未選択エラー
          setErrorMessage('モデルを選択してください。');
          console.error('モデル未選択');
          setIsLoading(false);
          return;
        }
  
        // レスポンスの処理
        if (chatRes && chatRes.status === 200) {
          const newMessage = {
            isUser: false,
            text: chatRes.data.message || chatRes.data,
            type: selectedModel.type,
          };
          setMessages((prevMessages) => [...prevMessages, newMessage]);
          const messagesToStore = [...updatedMessages, newMessage].slice(-15);
          setShowResolutionOptions(true);
  
          // メッセージをCookieに保存
          document.cookie = `messages=${JSON.stringify(
            messagesToStore
          )}; path=/`;
        } else {
          setErrorMessage(
            'エラーが発生しました。もう一度質問してください。'
          );
          console.error('chatResが正常でない:', chatRes);
        }
      } catch (error) {
        if (error.response) {
          console.error('レスポンスエラー:', error.response);
          setErrorMessage(
            error.response.data.message ||
            'サーバーからエラー応答が返されました。'
          );
        } else if (error.request) {
          console.error('リクエストエラー:', error.request);
          setErrorMessage('サーバーへの接続に失敗しました。');
        } else {
          console.error('未知のエラー:', error.message);
          setErrorMessage(`未知のエラー: ${error.message}`);
        }
      } finally {
        setIsLoading(false);
        setMessage('');
      }
    }
  };

  // ファイルをダウンロード
  const handleFileDownload = async (filename) => {
    console.log(`Attempting to download file: ${filename}`);
    try {
      const res = await axios.post(
        '/backend/download_pdf_file',
        {
          uniqueFileName: filename,
        },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
      alert('ファイルのダウンロードに失敗しました。');
    }
  };

  // コンテンツを描画
  const convertLinksOnly = (text) => {
    const sourcePrefix = 'ソース:';
    const pagePrefix = 'ページ:';
    let formattedText = text;
    let lastIndex = 0;
    const elements = [];

    while (
      formattedText.includes(sourcePrefix, lastIndex) &&
      formattedText.includes(pagePrefix, lastIndex)
    ) {
      const sourceIndex = formattedText.indexOf(sourcePrefix, lastIndex) + sourcePrefix.length;
      const pageIndex = formattedText.indexOf(pagePrefix, lastIndex);

      // 「ソース:」までの文字列をプッシュ
      elements.push(
        formattedText.substring(lastIndex, formattedText.indexOf(sourcePrefix, lastIndex))
      );

      // ファイル名（「ソース:」と「ページ:」の間）
      const filename = formattedText.substring(sourceIndex, pageIndex).trim();

      // ファイル名をリンク化 or テキストで表示
      if (/^[\w,\s-]+\.[A-Za-z]{3}$/.test(filename)) {
        elements.push(
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleFileDownload(filename);
            }}
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            {filename}
          </a>
        );
      } else {
        elements.push(<span>{filename}</span>);
      }

      // 「ページ:」から次の改行（またはテキスト末）までを追加
      const nextNewline = formattedText.indexOf('\n', pageIndex);
      if (nextNewline === -1) {
        elements.push(formattedText.substring(pageIndex));
        lastIndex = formattedText.length;
      } else {
        elements.push(formattedText.substring(pageIndex, nextNewline + 1));
        lastIndex = nextNewline + 1;
      }
    }

    // ループ後に残った部分
    if (lastIndex < formattedText.length) {
      elements.push(formattedText.substring(lastIndex));
    }

    return <>{elements}</>;
  };

  // メインのレンダリング関数（exportを外した）
  function renderMessageContent(text) {
    const refKeyword = '参照情報:';
    const refIndex = text.indexOf(refKeyword);

    if (refIndex === -1) {
      // 「参照情報:」がなければ、すべてマークダウンに
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(text),
          }}
        />
      );
    }

    // 参照情報: より上の部分だけマークダウン化
    const topText = text.slice(0, refIndex);

    // 参照情報: 以降はリンク生成のみ
    const bottomText = text.slice(refIndex);

    return (
      <>
        {/* 上の部分をマークダウン変換 */}
        <div
          dangerouslySetInnerHTML={{
            __html: marked(topText),
          }}
        />
        {/* 下の部分をリンク生成のみ */}
        {convertLinksOnly(bottomText)}
      </>
    );
  }

  // 「問題は解決しましたか？」の選択肢のハンドラー
  const handleResolutionChoice = (choice) => {
    console.log(`解決の選択肢: ${choice}`);
    setShowResolutionOptions(false);
    if (choice === 'はい') {
      setMessages((prev) => [...prev, { isUser: false, text: 'お役に立てて良かったです！トップ画面に戻ります。' }]);
      setTimeout(() => {
        navigate('/top', {
          state: { userId, notificationCount, notifications, userData },
        });
        setCurrentHistoryId(null);
      }, 2000);
    } else if (choice === '再度チャットで質問する') {
      // 再度チャットを開始
      setMessages((prev) => [...prev, { isUser: false, text: '再度質問をどうぞ。' }]);
    } else if (choice === '他の人に質問する') {
      setQuestionContent(lastUserMessage);
      navigate('/question', {
        state: {
          userId,
          questionContent: lastUserMessage,
          notificationCount,
          notifications,
          userData,
        },
      });
      setCurrentHistoryId(null);
    }
  };


  // トップ画面に戻る
  const moveTop = async () => {
    if (cookies.token !== '') {
      console.log('トップ画面に戻ります');
      try {
        const res = await axios.get(`/backend/me`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + cookies.token,
          },
        });

        console.log(res);
        if (res.data.login_ok === 'login_ok') {
          console.log(res.data);
          console.log('トップ画面に戻ります');
          navigate('/top', {
            state: {
              userId: res.data.id,
              userPassword: res.data.password,
            },
          });
        }
      } catch (error) {
        // トークンの有効期限が切れている場合、リフレッシュトークンを再取得する
        if (error.response.data.msg === 'Token has expired') {
          console.log('トークンの有効期限が切れています');
          console.log('リフレッシュトークンを再取得します');
          try {
            const res = await axios.get(`/backend/refresh`, {
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + cookies.refresh_token,
              },
            });

            console.log(res);
            removeCookie('token');
            setCookie('token', res.data.access_token);

            // 再取得したトークンでtop画面に戻る
            const updatedToken = res.data.access_token;
            await new Promise((resolve) => setTimeout(resolve, 0));
            console.log('トップ画面に戻ります');
            const res2 = await axios.get(`/backend/me`, {
              data: {},
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + updatedToken,
              },
            });

            console.log(res2);
            if (res2.data.login_ok === 'login_ok') {
              console.log('トップ画面に戻ります');
              navigate('/top', {
                state: {
                  userId: res2.data.id,
                  userPassword: res2.data.password,
                },
              });
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  // ユーザーの同意が得られた場合のみ画面を表示
  if (showConsentModal) {
    return <Consent onConsent={handleConsent} />;
  }

  // 初期表示画面
  if (showInitialScreen) {
    return (
      <Box
        sx={{
          height: '100vh',
          background: 'linear-gradient(to bottom, #e0f7fa, #80deea)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          animation: 'fadeIn 1s forwards',
        }}
      >
        {/* アニメーションのためのスタイルを追加 */}
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; }
              to { opacity: 1; }
            }
            @keyframes float {
              0% {
                transform: translatey(0px);
              }
              50% {
                transform: translatey(-20px);
              }
              100% {
                transform: translatey(0px);
              }
            }
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
          `}
        </style>
        <img
          src="character.png"
          alt="character"
          style={{
            width: '200px',
            height: 'auto',
            marginBottom: '20px',
            animation: 'float 3s ease-in-out infinite',
          }}
        />
        <Typography
          variant="h4"
          sx={{
            marginBottom: '20px',
            fontFamily: '"Noto Sans JP", sans-serif',
            color: '#00695c',
            fontWeight: '500',
          }}
        >
          まずはBISくんに聞いてみよう!
        </Typography>
        {/* ボタンを追加 */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowInitialScreen(false)}
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            padding: '12px 24px',
            borderRadius: '30px',
            textTransform: 'none',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          }}
        >
          はじめる
        </Button>
      </Box>
    );
  }

  return (
    <div>
      {/* チャットウィンドウ */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          position: 'relative',
          background: 'linear-gradient(to bottom, #f0f0f0, #d9d9d9)',
        }}
      >
        {/* ヘッダー部分 */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 2,
            backgroundColor: '#f0f0f0',
          }}
        >
          {/* 左側のBox */}
          <Box sx={{ position: 'relative' }}>
            {/* モデル選択ボタン */}
            <Button
              variant="outlined"
              onClick={handleModelButtonClick}
              sx={{
                marginLeft: 2,
                position: 'relative',
                zIndex: 2,
                display: 'flex',
                alignItems: 'center',
                padding: '8px 16px',
                minWidth: '200px',
                maxWidth: 'fit-content',
                border: '1px solid #ccc',
                borderRadius: '12px',
                backgroundColor: '#f9f9f9',
                color: '#333',
                fontSize: '14px',
                fontWeight: 500,
                textTransform: 'none',
                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  backgroundColor: '#f0f0f0',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                },
                '&:active': {
                  backgroundColor: '#e6e6e6',
                },
              }}
              id="model-select-button"
            >
              <span style={{ flexGrow: 1, textAlign: 'left' }}>
                {selectedModel?.type === 'standard' && '通常の ChatGPT'}
                {selectedModel?.type === 'internal' &&
                  `${selectedModel.name}の ChatGPT`}
                {!selectedModel && 'ChatGPTを選択'}
              </span>
              {/* 矢印アイコン */}
              <span
                style={{
                  fontSize: '16px',
                  color: '#888',
                  fontWeight: '400',
                  marginLeft: 'auto',
                }}
              >
                &gt;
              </span>
            </Button>

            {/* キャラクターの吹き出し */}
            {characterPopoverOpen && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '100%',
                  left: '75%',
                  transform: 'translate(-45%, 10px)',
                  padding: '20px',
                  minWidth: '340px',
                  height: 'auto',
                  borderRadius: '20px',
                  background: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
                  boxShadow:
                    '0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.15)',
                  textAlign: 'center',
                  zIndex: 999,
                  animation: 'fadeInPop 0.3s ease-out',
                  border: '1px solid #ddd',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {/* 吹き出しの矢印 */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '-12px',
                    left: '30%',
                    transform: 'translateX(-50%)',
                    width: 0,
                    height: 0,
                    borderLeft: '15px solid transparent',
                    borderRight: '15px solid transparent',
                    borderBottom: '15px solid #ffffff',
                    filter: 'drop-shadow(0 -4px 4px rgba(0, 0, 0, 0.1))',
                  }}
                />
                {/* キャラクター画像 */}
                <img
                  src="character.png"
                  alt="キャラクター"
                  style={{
                    width: '120px',
                    height: '150px',
                  }}
                />
                {/* テキスト */}
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '16px',
                    color: '#333333',
                    fontWeight: '600',
                    lineHeight: '1.5',
                    textAlign: 'left',
                    marginRight: '5px',
                    maxWidth: 'calc(100% - 140px)',
                  }}
                >
                  モデル選択ボタンを押して
                  <br />
                  モデルを選択してね！
                </Typography>
              </Box>
            )}
          </Box>

          {/* スキップボタン */}
          <Button
            variant="contained"
            color="secondary"
            startIcon={<SkipNextIcon />}
            onClick={moveTop}
            sx={{
              backgroundColor: '#ff5252',
              color: '#ffffff',
              '&:hover': {
                backgroundColor: '#ff1744',
              },
              fontWeight: 'bold',
              textTransform: 'none',
              borderRadius: '20px',
              padding: '6px 16px',
            }}
          >
            スキップ
          </Button>
        </Box>

        {/* モデル選択ポップオーバー */}
        <StyledPopover
          open={modelPopoverOpen}
          anchorEl={modelPopoverAnchorEl}
          onClose={() => {
            setModelPopoverOpen(false);
            setCurrentPath([]);
          }}
        >
          {/* ポップオーバーの内容 */}
          <Box sx={{ padding: '20px' }}>
            {currentPath.length > 0 && (
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton onClick={handleBack} sx={{ p: 0 }}>
                  <ArrowBackIcon sx={{ fontSize: '24px', color: '#333' }} />
                </IconButton>
                <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ ml: 1 }}>
                  {currentPath.map((node) => (
                    <Typography
                      key={node.id}
                      sx={{ fontSize: '16px', color: '#555' }}
                    >
                      {node.name}
                    </Typography>
                  ))}
                </Breadcrumbs>
              </Box>
            )}
            {/* リスト形式で表示 */}
            <List>
              {currentPath.length === 0 && (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => handleModelSelect('standard')}>
                    <ListItemText
                      primary="通常のChatGPT"
                      secondary="一般的な質問に適しています。"
                      primaryTypographyProps={{ fontWeight: 'bold' }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              {renderHierarchy(currentNodes)}
            </List>
          </Box>
        </StyledPopover>

        {/* ラベル有無のダイアログ */}
        <Dialog
          open={labelMissingDialogOpen}
          onClose={() => setLabelMissingDialogOpen(false)}
          aria-labelledby="label-missing-dialog-title"
          aria-describedby="label-missing-dialog-description"
        >
          <DialogTitle id="label-missing-dialog-title">
            ラベルが表示されていません
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="label-missing-dialog-description">
              このモデルは使えません。管理者にご連絡ください。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setLabelMissingDialogOpen(false)}
              color="primary"
            >
              閉じる
            </Button>
          </DialogActions>
        </Dialog>

        {/* アニメーションのためのスタイルを追加 */}
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; }
              to { opacity: 1; }
            }
            @keyframes float {
              0% {
                transform: translatey(0px);
              }
              50% {
                transform: translatey(-20px);
              }
              100% {
                transform: translatey(0px);
              }
            }
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
          `}
        </style>

        {/* メッセージ表示エリア */}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            position: 'relative',
            px: 2,
            py: 2,
            backgroundColor: 'transparent',
          }}
        >
          {isLoading && (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              }}
            >
              <CircularProgress size={60} />
              <Typography
                variant="subtitle1"
                sx={{ marginTop: '20px', fontWeight: 'bold' }}
              >
                処理中です、しばらくお待ちください...
              </Typography>
            </Box>
          )}
          <List>
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: msg.isUser ? 'flex-end' : 'flex-start',
                  marginBottom: '8px',
                  alignItems: 'flex-start',
                }}
              >
                {!msg.isUser && (
                  // キャラクター画像を表示
                  <img
                    src="character.png"
                    alt="character"
                    style={{
                      width: '40px',
                      height: 'auto',
                      marginRight: '8px',
                      cursor: 'pointer',
                      transition: 'transform 0.3s ease',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                    onClick={moveTop}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.transform = 'scale(1.05)')
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.transform = 'scale(1)')
                    }
                  />
                )}
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: msg.isUser ? '#81c784' : '#ffffff',
                    borderRadius: '12px',
                    maxWidth: '60%',
                    padding: '8px 16px',
                    boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    display: 'inline-block',
                  }}
                >
                  {msg.type === 'internal' ? (
                    <div
                      style={{ whiteSpace: 'pre-wrap', lineHeight: '1.2' }}
                    >
                      {renderMessageContent(msg.text)}
                    </div>
                  ) : (
                    <div
                      style={{
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.2',
                        margin: 0,
                        padding: '4px 0',
                        display: 'table',
                        verticalAlign: 'middle',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: marked(msg.text.trim()),
                      }}
                    />
                  )}
                </Box>
                {msg.isUser && (
                  <Avatar
                    alt="User"
                    sx={{
                      width: 40,
                      height: '40',
                      marginLeft: '8px',
                      backgroundColor: '#2196f3',
                    }}
                  >
                    <PersonIcon style={{ color: '#ffffff' }} />
                  </Avatar>
                )}
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </Box>

        {/* メッセージ入力エリア */}
        {selectedModel && !showResolutionOptions && !showFurtherOptions && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="メッセージを入力してください"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              multiline
              maxRows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendMessage}
                      sx={{
                        bgcolor: '#03a9f4',
                        '&:hover': { bgcolor: '#0288d1' },
                        color: '#ffffff',
                        marginLeft: '8px',
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '30px',
                },
              }}
            />
          </Box>
        )}

          {showResolutionOptions && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 3,
                backgroundColor: '#ffffff',
                borderTop: '1px solid #ccc',
                boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
              }}
            >
              <Typography variant="h6" sx={{ mb: 3, color: '#333333' }}>
                問題は解決しましたか？
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => handleResolutionChoice('はい')}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    backgroundColor: '#4caf50',
                    color: '#ffffff',
                    paddingX: 3,
                    paddingY: 1.5,
                    '&:hover': {
                      backgroundColor: '#388e3c',
                    },
                  }}
                >
                  はい
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleResolutionChoice('再度チャットで質問する')}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    backgroundColor: '#03a9f4',
                    color: '#ffffff',
                    paddingX: 3,
                    paddingY: 1.5,
                    '&:hover': {
                      backgroundColor: '#0288d1',
                    },
                  }}
                >
                  再度チャットで質問する
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleResolutionChoice('他の人に質問する')}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    backgroundColor: '#ff9800',
                    color: '#ffffff',
                    paddingX: 3,
                    paddingY: 1.5,
                    '&:hover': {
                      backgroundColor: '#fb8c00',
                    },
                  }}
                >
                  他の人に質問する
                </Button>
              </Box>
            </Box>
          )}
        {errorMessage && (
          <Typography color="error" sx={{ px: 2, py: 1 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default StartChat;
